import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import UserInfo from "../components/UserInfo/UserInfo";
import Disqus from "../components/Disqus/Disqus";
import PostTags from "../components/PostTags/PostTags";
import SocialLinks from "../components/SocialLinks/SocialLinks";
import SEO from "../components/SEO/SEO";
import Footer from "../components/Footer/Footer";
import Content from "../components/Content/Content";
import config from "../../data/SiteConfig";
import "./b16-tomorrow-dark.css";
import "./post.css";

import FullWidthImage2 from "../components/FullWidthImage2/FullWidthImage2";
// import img from "../../imgs/karsten-wurth-inf1783-55271-unsplash-cropped.jpg";
import img from "../imgs/american-public-power-association-419672-unsplash.jpg";

export default class PostTemplate extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    const { slug } = pageContext;
    const postNode = data.markdownRemark;
    const post = postNode.frontmatter;
    if (!post.id) {
      post.id = slug;
    }
    if (!post.category_id) {
      post.category_id = config.postDefaultCategoryID;
    }

    return (
      <Layout location={this.props.location}>
        <div className="about-container underNavbar">
          <Helmet htmlAttributes={{ lang: "en" }}>
            <title>{`${post.title} | ${config.siteTitle}`}</title>
          </Helmet>
          <SEO postPath={slug} postNode={postNode} postSEO />
          <FullWidthImage2 title="Tech Talks" image={img} />
          <Content style={{ paddingTop: 35 }}>
            <center>
              <h1 style={{ color: "black" }}>{post.title}</h1>
            </center>
          </Content>
          <Content style={{ padding: 110, paddingBottom: 50, paddingTop: 10 }}>
            <div dangerouslySetInnerHTML={{ __html: postNode.html }} />
            {/* <div className="post-meta">
              <PostTags tags={post.tags} />
              <SocialLinks postPath={slug} postNode={postNode} />
            </div> */}
            {/* <UserInfo config={config} />
            <Disqus postNode={postNode} />
            <Footer config={config} /> */}
          </Content>
        </div>
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        # cover
        date
        # category
        # tags
      }
      fields {
        slug
        date
      }
    }
  }
`;
